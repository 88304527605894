/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/

// import "./src/styles/sass/global.scss"
// need to convert this to CommonJS format if using exports (below)
const GlobalScss = require ("./src/styles/sass/global.scss")

// detect iOS devices
// https://stackoverflow.com/a/9039885
function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

// Called when the Gatsby browser runtime first starts.
exports.onClientEntry = () => {
  // console.log("onClientEntry")

  // ios low power / video autoplay
  // https://shaktisinghcheema.com/how-to-autoplay-video-on-mobile-devices-on-low-power-mode/
  Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
  });

}

// Called when the user changes routes, including on the initial load of the app
exports.onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("new pathname", location.pathname)
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null)

  // reset hamburger to black on white
  // console.log(document.querySelector('.hamburger').classList);
  document.querySelector('.hamburger')?.classList.remove('hamburger-invert');

  // // homepage loaded
  // if (location.pathname == '/') {
  //   const videoElement = document.getElementById('homepage-background-video');
  //   const videoFallback = document.getElementById('homepage-background-video-fallback');
  //   const is_iOS = iOS()
  //   // console.log({videoElement})
    
  //   // default (non-iOS)
  //   videoElement.style.display = 'block'
  //   videoFallback.style.display = 'none'

  //   // if iOS
  //   if (videoElement && is_iOS) {
  //     videoElement.addEventListener('suspend', () => {
  //       // suspend invoked
  //       // show the fallback image
  //       // console.log('suspend')
  //       if (window.innerWidth > 768) {
  //         // videoFallback.src = '/images/homepage/novideo-desktop.jpg'
  //         videoFallback.src = videoFallback.dataset.imageDesktop
  //       }
  //       else {
  //         // videoFallback.src = '/images/homepage/novideo-mobile.jpg'
  //         videoFallback.src = videoFallback.dataset.imageMobile
  //       }
  //       videoFallback.style.height = window.innerHeight + 'px'
  //       videoElement.style.display = 'none'
  //       videoFallback.style.display = 'block'
  //     });
  //     videoElement.addEventListener('play', () => {
  //       // video is played
  //       // hide the fallback image
  //       videoElement.style.display = 'block'
  //       videoFallback.style.display = 'none'
  //     });
  //   }
  // } // homepage

  
}

